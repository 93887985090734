<template>
<div class="conversation-text" :class="float === 'right' ? 'ml-0':'mr-0'">
    <div class="ctext-wrap">
        <div class="mb-1 font-12" v-html="chat.content"></div>
        <chat-files v-if="chat.files && chat.files.length" :list="chat.files"/>
        <p class="mb-2 pb-2 border-bottom" :class="float === 'right' ? '':'border-dark'" > <small style='font-size:10px;'>{{ chat.created_at | date_ago }}</small></p>
        <div v-if="chat.replyer_id" class="mb-2 py-2 text-right">
            <h6 class="font-weight-bold text-inherit text-right"> Replied:</h6>
            <div class="mb-1 text-right" v-html="chat.reply"></div>
            <small style='font-size:10px;'>{{ chat.replied_at | date_ago }}</small>
        </div>
        <slot v-else name="footer-action"></slot>
    </div>
</div>
</template>

<script>
import ChatFiles from './ChatFiles';

export default {
    components:{
        ChatFiles
    },
    props:{
        chat:{
            type: Object,
            required:true
        },
        float:{
            type: String,
            required:true
        }
    },
    computed: {

    },

};
</script>


<style lang="scss" scoped>
.border-bottom {
    border-bottom: 1px solid #c6c6c6 !important;
}
.border-dark {
    border-color: #a0a3a5 !important;
}
</style>
