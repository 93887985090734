
<template>
  <div>
    <div class="row">
        <div class="col-12">
           <vue-dropzone id="fileUploadDrop" ref="fileUploadDrop" class="border-0"
            :use-custom-slot="true">
              <div class="my-0 needsclick">
                  <i class="h1 text-primary mdi mdi-cloud-upload"></i>
                  <h4>Upload Files or Drop files</h4>
                  <span class="text-primary font-13">
                      <em>maximum of 5 files</em>
                  </span>
              </div>
          </vue-dropzone>
        </div>
    </div>
    <div class="row mt-2 justify-content-end">
        <div class="col-12">
          <button type="button" @click.prevent="sendAttachment()" class="btn btn-primary mt-2">
            Upload
          </button>
      </div>
    </div>
</div>
</template>

<script>
import VueDropzone from '@/components/VueDropzone.vue';

export default {
 name: "upload-attachment",
  components:{
    VueDropzone,
  },
  methods: {
    sendAttachment() {
      if(!this.collectFiles().length){ this.alertError("Please a file is required"); return;}
      const formData = new FormData()
      this.collectFiles().forEach((item, index) => formData.append('file_'+index, item))
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post('/chats/attachments', formData)
      .then((response) => {
        this.$store.dispatch("changeLoaderValue", false)
        if(response.data.success){
          this.$store.commit(`chatList/ADD_CHAT`, response.data.data)
          this.resetForm();
          this.scrollMessageAreaUp();
        }
      })
    },
    scrollMessageAreaUp(){
      this.$emit('scrollMessagesUp');
    },
    collectFiles(){
      let arrafile = [];
      if(this.$refs.fileUploadDrop){
        arrafile = [...this.$refs.fileUploadDrop.getAcceptedFiles()];
      }
      return arrafile
    },
    resetForm() {
      this.$refs.fileUploadDrop.removeAllFiles();
    },
  }
}

</script>

